@import "../../styles/Media";
// modal
.modal-bg {
    position: fixed;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 10;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    @media (max-width: $md) {
        padding: 0;
        width: 100%;
    }
    .modal-container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 430px;
        max-width: 100%;
        height: 100vh;
        @media (min-width: $md) {
            max-height: 90vh;
            height: auto;
        }
        .modal {
            overflow-y: auto;
            position: relative;
            display: block;
            padding: 18px 18px;
            // width: calc(~"100% - 56px");
            background: #fff;
            //for settings page
            @media (min-width: $md) {
                border-radius: 20px;
            }
            &.add-card {
                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 12px 0;

                    &:not(:last-child) {
                        border-bottom: 0.01rem solid #dddddd;
                    }
                    .paymethod-choose {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }

                    &:before {
                        content: "";
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        color: white;
                        width: 56.5px;
                        height: 40px;
                        margin-right: 10px;
                        border: 1px solid transparent;
                        border-radius: 4px;
                    }

                    &:hover:before,
                    &.active:before {
                        transition: all 1.5s;
                        border: 1px solid #000;
                    }
                }
            } 
        }

        .btn-close {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            align-items: flex-start;
            &:before {
              //  content: url(./../../img/btn_close.svg);
                width: 24px;
                height: 24px;
            }
        }
    }
}
//responsive
@media only screen and (max-width: 768px) {
    // .modal-bg {
    //     .modal-container {
    //         width: 100%;
    //         max-width: 100%;
    //         position: absolute;
    //         bottom: 0;
    //         animation: appear_from_bottom 0.3s ease;
    //         .modal {
    //             width: 100%;
    //             border-radius: 16px 16px 0 0;
    //         }
    //         .btn-close {
    //             display: none;
    //         }
    //         @keyframes appear_from_bottom {
    //             0% {
    //                 bottom: -100%;
    //             }
    //             100% {
    //                 bottom: 0%;
    //             }
    //         }
    //     }
    // }
}

.close {
    width: 16px;
    height: 17px;
    background-size: 100% 100%;
    background-image: url("../../assets/img/close.svg");
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 20px;
}

.header {
    border-bottom: 1px solid #F4F4FC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -18px -18px 10px -18px;
    padding: 18px 18px 17px 18px;
    position: fixed;
	z-index: 1;
    width: 430px;
    max-width: 430px;
    background: #fff;
    box-sizing: border-box;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    @media (max-width: $sm) {
        width: 100%;
    }
}

.content {
    margin-top: 70px;
}

.modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}