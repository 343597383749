@import "./../Media";

$animations-speed: 2s;

@keyframes logo-mobile {
  from {
    position: absolute;
    top: calc(45vh - 60px);
  }

  to {
    top: 0;
    position: relative;
  }

  30% {
    position: absolute;
    top: calc(45vh - 60px);
  }
}


@keyframes container {
  from {
    height: 100vh;
   overflow: hidden;
  }

  to {
    height: auto;
    overflow: visible;
  }

  55% {
    height: 100vh;
    overflow: hidden;
  }
}

@keyframes logo {
  from {
    left: 50%;
    top: 50%;
    margin-left: -112px;
    margin-top: -68px;
    @media (max-width: $md-max) {
      margin: 0 0 -50% 0;
      position: inherit;
      left: auto;
    }
  }

  to {
    margin: 0;
    left: 0;
    top: 0;
  }

  55% {
    left: 50%;
    top: 50%;
    margin-left: -112px;
    margin-top: -68px;
    @media (max-width: $md-max) {
      left: auto;
      margin: 0 0 -50% 0;
      position: inherit;
    }
  }
}


@keyframes left-side {
  from {
    top: 100%;
  }

  to {
    top: 13vh;
  }

  55% {
    top: 100%;
  }
}
@keyframes left-side-mobile {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0);
  }

  55% {
    transform: translateY(100vh);
  }
}

@keyframes right-side-mobile {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0);
  }

  55% {
    transform: translateY(100vh);
  }
}

@keyframes right-side {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }

  55% {
    top: 100%;
  }
}

@keyframes bottom-bar-mobile {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(100);
  }

  55% {
    transform: translateY(100vh);
  }
}

@keyframes bottom-bar {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0;
  }

  55% {
    bottom: -100%;
  }
}




@keyframes card1 {
  from {
    right: 100%;
  }

  to {
    right: 32%;
  }

  55% {
    right: 100%;
  }
}

@keyframes card_opacity {
  to {
    opacity: 1;
  }
  from {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
}

@keyframes card2 {
  from {
    right: -300px;
  }
  to {
    right: 0;
  }
  55% {
    right: -300px;
  }
}


