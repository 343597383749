@import "./animations/App-animations";
@import "./Media";

#root {
  top: 0;
  overflow: hidden;
  height: 100vh;
  @media (max-width: $md-max) {
    height: auto;
    overflow: auto;
  }
}
.App {
  padding-top: 60px;
  height: calc(100% - 60px);
  @media (max-width: $md) {
    padding-top: 40px;
  }
}

.logo {
  pointer-events: none;
  position: absolute;
  @media (max-width: $md) {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  img {
    min-height: 16px;
    height: 5vh;
    @media (max-width: $md-max) {
      height: auto;
      width: 59.73vw;
    }
  }
}

.left-side {
  top: 13vh;
  position: absolute;
  @media (max-width: $md-max) {
    position: relative;
    display: flex;
    top: 0;
    margin-top: 14vw;
    flex-direction: column;
    align-items: center;
    margin-bottom: 13vw;
  }
}

.right-side {
  margin-left: auto;
  height: calc(100vh - 60px);
  width: 585px;
  position: relative;
  @media (max-width: $md-max) {
    width: 100%;
    height: auto;
  }
}


.title {
  font-size: 4vh;
  font-weight: bold;
  line-height: 5.5vh;
  padding-bottom: 1.46vh;
  position: relative;
  @media (max-width: $md-max) {
    font-size: 6.4vw;
    line-height: 8vw;
    padding-bottom: 0;
    margin-bottom: 10vw;
    text-align: center;
  }

  &:after {
    display: block;
    content: '';
    bottom: 1.5vh;
    left: -0.68vh;
    position: absolute;
    height: 0.8vh;
    width: 50vh;
    background-size: cover;
    background-image: url("./../assets/img/ink-shape.svg");
    @media (max-width: $md-max) {
      bottom: -2.5vw;
      left: -0.68vw;
      position: absolute;
      height: 3vw;
      width: 65vw;
    }
  }

}

.desc {
  font-weight: 400;
  font-size: 2vh;
  line-height: 2.5vh;
  @media (max-width: $md-max) {
    width: 60vw;
    font-size: 4.26vw;
    line-height: 5.86vw;
    text-align: center;
  }
}

.market-links {
  margin-top: 3.9vw;
  @media (max-width: $md-max) {
    display: flex;
    width: 90vw;
    justify-content: space-between;
  }
}

.market {
  cursor: pointer;
  position: relative;
  height: 5vh;
  display: inline-block;

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &:after {
    transition: opacity .5s ease;
    display: block;
    content: '';
    opacity: 0;
    top: -.88vh;
    left: -0.68vh;
    position: absolute;
    height: 8.59vh;
    width: 19.53vh;
    background-size: cover;
    background-image: url("./../assets/img/hover.svg");
  }

  &:first-child {
    margin-right: 5vh;
    @media (max-width: $md-max) {
      margin-right: 0;
    }
  }

  img {
    height: 5vh;
    @media (max-width: $md-max) {
      width: 43vw;
      height: auto;
    }
  }
}


.side-container {
  position: relative;
}


.card_1 {
  position: absolute;
  z-index: 1;
  top: 26.6vh;
  right: 32%;
  transition: all ease .4s;
  @media (max-width: $md-max) {
    top: 45vw;
  }

  img {
    height: 30vh;
    @media (max-width: $md-max) {
      width: 58.6vw;
      height: auto;
    }
  }

  &:hover {
    @media (min-width: $md) {
      transform: scale(1.1) translateY(-2vh);
    }

    ~ .iphone {
      @media (min-width: $md) {
        transform: translateY(-3vh);
      }
    }
  }
}


.iphone {
  transition: all ease .6s;
  position: absolute;
  z-index: 0;
  right: 10%;
  @media (max-width: $md-max) {
    position: relative;
    right: 0;
    text-align: center;
  }

  img {
    height: calc(79vh);
    @media (max-width: $md-max) {
      width: 65.3vw;
      height: auto;
    }
  }

}

.card_2 {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 55.6vh;
  transition: all ease .4s;
  @media (max-width: $md-max) {
    top: 93vw;
  }

  &:hover {
    transform: scale(1.1) translateY(-2vh);

    + .iphone {
      transform: translateY(-3vh);
    }
  }

  img {
    height: 10vh;
    @media (max-width: $md-max) {
      width: 58.6vw;
      height: auto;
    }
  }
}

.bottom-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: $md-max) {
    grid-template-columns: 1fr;
  }
}

.bottom {
  position: absolute;
  width: 100%;
  height: 25.5vh;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(5px);
  border-radius: 30px 30px 0 0;
  padding: 3.9vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $md-max) {
    height: auto;
    position: relative;
    margin-top: -20vw;
  }
}

.bar-content {
  display: flex;
  align-items: center;

  @media (max-width: $md-max) {
    flex-direction: column;
    font-size: 2.3vh;
    line-height: 3vh;
  }
}

.bar-item {
  display: flex;
  align-items: start;
  justify-content: center;
  font-size: 1.56vh;
  line-height: 2.1vh;
  font-weight: bold;
  @media (max-width: $md-max) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 4vw;
  }
}

.bar-icon {
  margin-right: 1.92vh;
  @media (max-width: $md-max) {
    margin-right: 0;
    margin-bottom: 2vh;
  }

  img {
    height: 8.8vh;
    @media (max-width: $md-max) {
      height: 24vw;
      width: 24vw;
    }
  }
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  @media (max-width: $md) {
    position: absolute;
    width: 100%;
  }
  @media (min-width: $sm) {
    width: 100%;
  }
  @media (min-width: $md) {
    width: 90vw;
  }
  @media (min-width: $lg) {
    width: 1000px;
  }
  @media (min-width: $xl) {
    width: calc(80vw);
  }

}

@media (prefers-reduced-motion: no-preference) {
  #root {
    @media (max-width: $md-max) {
      animation: container $animations-speed linear;
    }
  }
  .logo {
    animation: logo $animations-speed linear;
    @media (max-width: $md-max) {
      animation: logo-mobile $animations-speed linear;
    }
  }
  .left-side {
    animation: left-side $animations-speed linear;
    @media (max-width: $md-max) {
      animation: left-side-mobile $animations-speed linear;
    }

  }
  .right-side {
    animation: right-side $animations-speed linear;
    @media (max-width: $md-max) {
      animation: right-side-mobile $animations-speed linear;
    }
  }
  .bottom {
    animation: bottom-bar $animations-speed linear;
    @media (max-width: $md-max) {
      animation: bottom-bar-mobile $animations-speed linear;
    }
  }
  .card_1 {
    animation: card1 $animations-speed linear, card_opacity $animations-speed + 0.3s linear;
  }
  .card_2 {
    animation: card2 $animations-speed linear, card_opacity $animations-speed + 0.3s linear;
  }

  .links-container {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md-max) {
      flex-direction: column;
      align-items: center;
    }
  }

  .card-icons {
    width: 23vh;

    img {
      width: 100%;
    }
  }
}


.links {
  @media (max-width: $md-max) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3vw;
    margin-top: 15vw;
  }
}
.links-item {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: #8E8E93;
  font-size: 1.7vh;
  font-weight: 400;
  margin-right: 4vh;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-right: 0;
  }
  @media (max-width: $md-max) {
    margin-bottom: 3vw;
    margin-right: 0;
  }
}

a {
  color: #2CB66F;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
