@font-face {
  font-family: "Noto Sans";
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/Noto_Sans/NotoSans-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/Noto_Sans/NotoSans-Bold.ttf') format("truetype");
}

body {
  margin: 0;
  font-family: 'Noto Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overflow-hide {
  overflow: hidden;
}

h2 {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
