.info {
  p {
    text-align: justify;
    text-indent: 20px; /* Отступ первой строки в пикселах */
  }
}

.colortext {
  background-color: yellow; /* Красный цвет выделения */
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
